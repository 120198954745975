<template>
    <!--begin::CommitteeMembersList-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Committee Members List</h3>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example mt-5">
          <v-data-table
            :headers="committee_headers"
            :items="committee_list"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.first_name }} {{ item.last_name }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <!-- </form> -->
      </div>
    </div>
    <!--end::CommitteeMembersList-->
</template>

<script>

import Vuetify from "vuetify";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "CommitteeMembersList",
  vuetify: new Vuetify(),
  data() {
    return {
      committee_list: [],
      committee_headers: [
        { text: "Member Name", align: "left"},
      ]
    }
  },
  props:{
    committee_id : String
  },
  mounted() {
    let context = this;
    context.getCommitteeList();
  },
  methods: {
    getCommitteeList() {
      let context = this;
      axios({
        method: "get",
        url: "get-committee-members?committee_id=" + context.committee_id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.committee_list = result.data.committee_members;
        },
        function() {
          Swal.fire(
            "Error",
            "Error in fetching Committee Member list",
            "info"
          );
        }
      );
    }
  }
}

</script>
