<template>
    <!--begin::Assignment-->
    <div class="card card-custom gutter-b" v-if="committee_id">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Assignments</h3>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example">
          <v-form ref="task_form" v-model="valid" lazy-validation>
            <v-row no-gutters>
              <v-text-field
                cols="12"
                sm="12"
                v-model="task.task_name"
                :rules="nameRules"
                label="Task Name"
                required
                outlined
                dense
                class="pr-2"
              ></v-text-field>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12">
                <v-select
                  :items="task_types"
                  v-model="task.task_type"
                  label="Types"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>

            <v-row no-gutters v-if="task.task_type == 'Training Video'">
              <v-col cols="3" class="pb-5">
                <v-dialog
                  transition="dialog-bottom-transition"
                  max-width="600"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >Select Video</v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-toolbar
                        color="primary"
                        dark
                      >Select video</v-toolbar>
                      <v-card-text>
                        <div class="text-h2 pa-12">
                          <v-select
                            :items="videos_list"
                            label="Select videos"
                            item-text="video_name"
                            v-model="video_name"
                            outlined
                          ></v-select>  
                        </div>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn
                          text
                          @click="dialog.value = false"
                        >Close</v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-col>
              <v-col cols="6">
                <h6>
                  {{ video_name }}
                </h6>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="task.task_type == 'Document Review'">
              <v-col cols="6">
                <v-file-input
                  show-size
                  label="Document file"
                  dense
                  outlined
                  ref="file"
                  v-model="task.document"
                >
                </v-file-input>
              </v-col>
            </v-row>

            <v-row class="card-footer p-0" no-gutters>
              <v-btn
                v-if="!task_id"
                :disabled="!valid"
                color="success"
                class="mr-4 mt-5"
                @click="validate"
              >
                Submit
              </v-btn>
              <v-btn
                v-else
                :disabled="!valid"
                color="success"
                class="mr-4 mt-5"
                @click="update"
              >
                Update
              </v-btn>
              <v-btn
                class="mr-4 mt-5"
                @click="taskFormReferesh"
              >
                Reset
              </v-btn>
            </v-row>
          </v-form>
        </div>
        <div class="example mt-5">
          <v-data-table
            :headers="task_headers"
            :items="task_list"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.task_type }}
                </td>
                <td>{{ item.task_name }}</td>
                <td>
                  <!-- <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        fab
                        small
                        v-on="on"
                        @click="taskEdit(item.id)"
                      >
                        <v-icon color="#B5B5C3">mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Task</span>
                  </v-tooltip> -->
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        fab
                        small
                        v-on="on"
                        @click="taskDelete(item.id)"
                      >
                        <v-icon color="#B5B5C3">mdi-delete-forever</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Task</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <!-- </form> -->
      </div>
    </div>
    <!--end::Assignment-->
</template>

<script>

import Vuetify from "vuetify";
import axios from "axios";
import Swal from "sweetalert2";
import { DELETE_TASK } from "@/core/services/store/committee.module";

export default {
  name: "AssignmentTask",
  vuetify: new Vuetify(),
  data() {
    return {
      task: {
        task_name: "",
        task_type: "General Task",
        training_video_id: 0,
        document: [],
      },
      videos_list: [],
      task_id: this.$route.params.task_id,
      valid: true,
      video_name: "No training video selected",
      nameRules: [
        v => !!v || "Task Name is required",
        v =>
          (v && v.length <= 25) || "Task Name must be less than 25 characters"
      ],
      task_list: [],
      task_types: ["General Task", "Training Video", "Document Review"],
      task_headers: [
        { text: "Task Type", align: "left", value: "task_type", width: "25%" },
        { text: "Task", value: "task_name", width: "35%" },
        { text: "Action", sortable: false, value: "action", width: "20%" }
      ]
    }
  },
  props:{
    committee_id : String
  },
  mounted() {
    let context = this;
    context.getVideosList();
    context.getTaskList();
  },
  watch: {
    video_name: function(newVal) {
      this.videos_list.forEach(video => {
        if(video.video_name == newVal) {
          this.task.training_video_id = video.video_id;
        }
      });
    }
  },
  methods: {
    taskFormReferesh() {
      let context = this;
      context.task.task_name = "";
      context.task.task_type = "General Task";
      context.task.training_video_id = 0;
      context.task.document = [];
    },
    getTaskList() {
      let context = this;
      axios({
        method: "get",
        url: "assignment_task/details/" + context.committee_id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.task_list = result.data.assignment_task;
        },
        function() {
          Swal.fire(
            "Error",
            "Error in fetching Training Video list",
            "info"
          );
        }
      );
    },
    taskEdit(task_id) {
      let context = this;
      axios({
        method: "get",
        url: "assignment_task/" + task_id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.task = result.data.assignment_task_details;
          context.task_id = task_id;
        },
        function() {
          Swal.fire(
            "Error",
            "Error in fetching task",
            "info"
          );
        }
      );
    },
    taskDelete(task_id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this Task details!",
        showCancelButton: true,
        confirmButtonText: "Delete"
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store.dispatch(DELETE_TASK, task_id).then(() => {
            this.getTaskList();
          });
        } else if (result.isDismissed) {
          Swal.fire("Committee is safe.", "", "info");
        }
      });
    },
    getVideosList() {
      let context = this;
      axios({
        method: "get",
        url: "training-video",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          var list = result.data.training_videos;
          list.forEach(video => {
            context.videos_list.push({"video_id": video.id, "video_name": video.name});
          });
        },
        function() {
          Swal.fire(
            "Error",
            "Error in fetching Training Video list",
            "info"
          );
        }
      );
    },
    validate() {
      if (this.$refs.task_form.validate()) {
        this.submitTask();
      } else {
        this.snackbar = true;
      }
    },
    update() {
      if (this.$refs.task_form.validate()) {
        this.updateTask();
      } else {
        this.snackbar = true;
      }
    },
    submitTask() {
      let context = this;
      var formData = new FormData();
      formData.append("committee_id", this.committee_id);
      formData.append("task_name", this.task.task_name);
      formData.append("task_type", this.task.task_type);
      formData.append("document", this.task.document);
      formData.append("training_video_id", this.task.training_video_id);
      formData.append("parent_of_task", "committee");
      formData.append("task_category", "committee");

      axios.post(process.env.VUE_APP_ACBACK_URL +
        "assignment_task", formData).then(
        function(result) {
          // context.$router.go(-1);
          Swal.fire("Success", result.data.message, "success");
          context.getTaskList();
          context.taskFormReferesh();
        },
        function() {
          Swal.fire("Error", "Error in save Training videos", "error");
        }
      );
    },
    updateTask() {
      
    }
  }
}

</script>
