<template>
  <div>
    <!--begin::Committee-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label" v-if="committee_id">Edit Committee</h3>
          <h3 class="card-label" v-else>Add Committee</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <button
                type="button"
                class="ma-2 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span class="v-btn__content" @click="redirectToCommitteeList()">
                  Back
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example">
          <v-form ref="committee_form" v-model="valid" lazy-validation>
            <v-row no-gutters>
              <v-text-field
                cols="12"
                sm="12"
                v-model="committee.name"
                :rules="nameRules"
                label="Committee Name"
                required
                outlined
                dense
                class="pr-2"
              ></v-text-field>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="12">
                <v-textarea
                  v-model="committee.description"
                  label="Description"
                  outlined
                  dense
                  :rules="descriptionRules"
                ></v-textarea>
              </v-col>
            </v-row>

             <v-row no-gutters>
              <v-col cols="12">
                <v-combobox
                  v-model="selectedOptions"
                  :items="options"
                  label="Options"
                  multiple
                  chips
                  outlined
                  dense
                ></v-combobox>
              </v-col>
            </v-row>

            <v-row class="card-footer p-0" no-gutters>
              <v-btn
                v-if="!committee_id"
                :disabled="!valid"
                color="success"
                id="contact_us_submit"
                class="mr-4 mt-5"
                @click="validate"
              >
              <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                Submit
              </v-btn>
              <v-btn
                v-else
                :disabled="!valid"
                color="success"
                id="contact_us_submit"
                class="mr-4 mt-5"
                @click="update"
              >
              <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                Update
              </v-btn>
            </v-row>
          </v-form>
        </div>
        <!-- </form> -->
      </div>
    </div>
    <!--end::Committee-->

    <template v-if="committee_id">
      <assignment-task
        :committee_id="committee_id.toString()"
      >
      </assignment-task>
    </template>
    
    <template v-if="committee_id">
      <committee-members-list
        :committee_id="committee_id.toString()"
      >
      </committee-members-list>
    </template>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import EventBus from "@/core/services/store/event-bus";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { STORE_COMMITTEE, UPDATE_COMMITTEE } from "@/core/services/store/committee.module";
import Swal from "sweetalert2";
import AssignmentTask from './AssignmentTask.vue';
import CommitteeMembersList from './CommitteeMembersList.vue';

export default {
  name: "CommitteeForm",
  vuetify: new Vuetify(),
  components: {
    AssignmentTask,
    CommitteeMembersList
  },
  data() {
    return {
      committee: {
        name: "",
        description: "",
        options: []
      },
      committee_id: this.$route.params.id,
      selectedOptions: [],
      options: [],
      descriptionRules: [v => !!v || "Description is required"],
      nameRules: [
        v => !!v || "Committee Name is required",
        v =>
          (v && v.length <= 25) || "Committee Name must be less than 25 characters"
      ],
      valid: true,
      disable_btn: false
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    let context = this;
    context.getOptions();
    // set the tab from previous
    context.$store.dispatch(SET_BREADCRUMB, [{ title: "Committee" }]);
    EventBus.$on("STORE_COMMITTEE", function(payLoad) {
      if (payLoad) {
        context.redirectToCommitteeList();
      } else {
        Swal.fire("Error", "Please try again", "error");
      }
    });
    EventBus.$on("UPDATE_COMMITTEE", function(payLoad) {
      if (payLoad) {
        context.redirectToCommitteeList();
      }
    });
    EventBus.$on("UPDATE_COMMITTEE_ERROR", function(payLoad) {
        context.$router.push({ name: "CommitteeList" });
    });
    if (this.$route.params.id) {
      context.edit_form = true;
      axios({
        method: "get",
        url: "committee/" + this.$route.params.id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.committee) {
            context.committee = result.data.committee;
            context.committee.options.forEach(option => {
              context.selectedOptions.push(option.option);
            });
          } else {
            context.$router.go(-1); //go back to list
            context.disable_btn = false;
            Swal.fire(result.data.error, "", "error");
          }
        },
        function() {
          context.$router.go(-1); //go back to list
          context.disable_btn = false;
          Swal.fire("Error", "Committee not found!", "error");
        }
      );
    }
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    redirectToCommitteeList() {
      let context = this;
      context.$router.go(-1); //go back to list
    },
    validate() {
      let context = this;
      if (this.$refs.committee_form.validate()) {
        context.disable_btn = true;
        this.submitCommittee();
      } else {
        this.snackbar = true;
      }
    },
    update() {
      let context = this;
      if (this.$refs.committee_form.validate()) {
        context.disable_btn = true;
        this.updateCommittee();
      } else {
        this.snackbar = true;
      }
    },
    updateCommittee() {
      let context = this;
      context.committee.options = context.selectedOptions;

      this.$store.dispatch(UPDATE_COMMITTEE, context.committee).then(() => {
        context.disable_btn = false;
      });
    },
    submitCommittee() {
      let context = this;
      context.committee.options = context.selectedOptions;

      this.$store.dispatch(STORE_COMMITTEE, context.committee).then(() => {
        context.disable_btn = false;
      });
    },
    getOptions() {
      let context = this;
      axios({
        method: "post",
        url: "get-options",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.committee_options) {
            result.data.committee_options.forEach(option => {
              context.options.push(option.option);
            });
          } else {
            Swal.fire(result.data.error, "", "error");
          }
        },
        function() {
          Swal.fire("Error", "Option fetch error!", "error");
        }
      );
    }
  }
};
</script>
